<template>
  <section class="text-center highlights">
    <v-container grid-list-s text-xs-center class="pa-14">
      <v-row>
        <v-col>
          <h2 class="text-sm-h4 font-weight-bold text-justify primary--text">
            {{ $t('wasiaAssetsIntro') }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="pt-4">
        <v-col lg="4">
          <v-dialog max-width="550">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                hover
                height="100%"
                class="mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10"
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex">
                  <v-icon
                    x-large
                    color="primary"
                    title="certificate icon"
                    class="custom-icon text-center ma-auto pa-10"
                  >
                    mdi-certificate-outline
                  </v-icon>
                </div>
                <h3 class="secondary--text font-weight-black text-sm-h5">
                  {{ $t('wasiaSafety') }}
                </h3>
              </v-card>
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2 font-weight-bold">
                {{ $t('wasiaSafety') }}
              </v-card-title>
              <v-card-text class="subtitle-1 py-4">
                {{ $t('wasiaSafetyText') }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col lg="4">
          <v-dialog max-width="550">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                hover
                height="100%"
                class=" mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10"
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex">
                  <v-icon
                    x-large
                    color="primary"
                    title="certificate icon"
                    class="custom-icon text-center ma-auto pa-10"
                  >
                    mdi-account-multiple-outline
                  </v-icon>
                </div>
                <h3 class="secondary--text font-weight-black text-sm-h5">
                  {{ $t('wasiaConfidentiality') }}
                  <br /><br />
                </h3>
              </v-card>
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2 font-weight-bold">
                {{ $t('wasiaConfidentiality') }}
              </v-card-title>
              <v-card-text class="subtitle-1 py-4">
                {{ $t('wasiaConfidentialityText') }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col lg="4">
          <v-dialog max-width="550">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                hover
                height="100%"
                class="mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10"
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex">
                  <v-icon
                    x-large
                    color="primary"
                    title="certificate icon"
                    class="custom-icon text-center ma-auto pa-10"
                  >
                    mdi-lock-outline
                  </v-icon>
                </div>
                <h3 class="secondary--text font-weight-black text-sm-h5">
                  {{ $t('wasiaSecurity') }}
                </h3>
              </v-card>
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2 font-weight-bold">
                {{ $t('wasiaSecurity') }}
              </v-card-title>
              <v-card-text class="subtitle-1 py-4">
                {{ $t('wasiaSecurityText') }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetsSection'
})
</script>
<style lang="scss">
.custom-icon {
  font-size: 6rem !important;
}
</style>
