import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@/scss/variables.scss'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
import ar from 'vuetify/es5/locale/ar'
import { selectedLocale } from '@/plugins/i18n'

const vuetify = new Vuetify({
  lang: {
    locales: { en, fr, ar },
    current: selectedLocale
  },
  rtl: selectedLocale === 'ar',
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#00665b',
        secondary: '#797979',
        accent: '#212529',
        error: '#e23a51',
        info: '#57c0bb',
        success: '#538e71',
        warning: '#f5d36b'
      }
    }
  }
})

Vue.use(Vuetify)

export default vuetify
