import { legalNotice, termsAndServices } from '@/api/legal.api'

const state = {
  legalNotice: '',
  termsAndServices: ''
}

const getters = {
  legalNotice: state => state.legalNotice,
  termsAndServices: state => state.termsAndServices
}

const actions = {
  async getLegalNotice({ commit }) {
    await legalNotice()
      .then(resp => {
        commit('setLegalNotice', resp.data.text)
      })
      .catch(err => console.log(err))
  },
  async getTermsAndServices({ commit }) {
    await termsAndServices()
      .then(resp => {
        commit('setTermsAndServices', resp.data.text)
      })
      .catch(err => console.log(err))
  }
}

const mutations = {
  setLegalNotice(state, legalNotice) {
    state.legalNotice = legalNotice
  },
  setTermsAndServices(state, termsAndServices) {
    state.termsAndServices = termsAndServices
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
