import { getUser, patchUser } from '@/api/accounts.api'

const state = {
  userProfile: {},
  userTrustedPerson: {},
  isLoadingUser: false
}

const getters = {
  userProfile: state => state.userProfile,
  userTrustedPerson: state => state.userTrustedPerson,
  isLoadingUserUser: state => state.isLoadingUser
}

const actions = {
  async getUser({ commit }) {
    commit('getUserRequest')
    await getUser()
      .then(resp => {
        commit('setUser', resp.data)
        commit('setUserTrustedPerson', resp.data.trusted_person || {})

        return resp
      })
      .catch(err => {
        commit('getUserError')
        return err
      })
  },
  async patchUser({ commit }, data) {
    commit('patchUserRequest')
    return await patchUser(data)
      .then(resp => {
        commit('setUser', resp.data)
        commit('setUserTrustedPerson', resp.data.trusted_person || {})

        return resp
      })
      .catch(err => {
        commit('patchUserError')
        return err
      })
  }
}

const mutations = {
  setUser(state, user) {
    state.userProfile = user
    state.isLoadingUser = false
  },
  setUserTrustedPerson(state, userTrustedPerson) {
    state.userTrustedPerson = userTrustedPerson
  },
  getUserError() {
    state.isLoadingUser = false
  },
  getUserRequest(state) {
    state.isLoadingUser = true
  },
  patchUserError() {
    state.isLoadingUser = false
  },
  patchUserRequest(state) {
    state.isLoadingUser = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
