<template>
  <section class="quotes text-center py-2 py-md-16">
    <v-container class="pa-6">
      <v-row class="my-1 my-md-5">
        <v-col sm="12" lg="4">
          <p class="secondary--text text-h5 text-lg-justify font-weight-bold">
            {{ $t('quoteTitle_1') }}
          </p>
        </v-col>
        <v-col sm="12" lg="7" offset-lg="1">
          <p class="secondary--text text-h5 text-lg-justify font-italic">
            {{ $t('quoteText_1') }}
          </p>
        </v-col>
      </v-row>
      <v-row class="my-1 my-md-5">
        <v-col lg="4" sm="12">
          <p class="secondary--text text-h5 text-lg-justify font-weight-bold">
            {{ $t('quoteTitle_2') }}
          </p>
        </v-col>
        <v-col sm="12" lg="7" offset-lg="1">
          <p class="secondary--text text-h5 text-lg-justify font-italic">
            {{ $t('quoteText_2') }}
          </p>
        </v-col>
      </v-row>
      <v-row class="my-1 my-md-5">
        <v-col sm="12" lg="4">
          <p class="secondary--text text-h5 text-lg-justify font-weight-bold">
            {{ $t('quoteTitle_3') }}
          </p>
        </v-col>
        <v-col sm="12" lg="7" offset-lg="1">
          <p class="secondary--text text-h5 text-lg-justify font-italic">
            {{ $t('quoteText_3') }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'QuotesSection'
})
</script>

<style lang="scss">
.quotes {
  background-color: #eff0ef;
}
</style>
