import { faqs } from '@/api/faqs.api'

const state = {
  faqs: ''
}

const getters = {
  faqs: state => state.faqs
}

const actions = {
  async getFAQs({ commit }) {
    await faqs()
      .then(resp => {
        commit('setFAQs', resp.data)
      })
      .catch(err => console.log(err))
  }
}

const mutations = {
  setFAQs(state, faqs) {
    state.faqs = faqs
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
