import {
  listNotifications,
  listMoreNotifications,
  setIsRead
} from '@/api/notifications.api'

const state = {
  notifications: [],
  isLoading: false,
  loadMore: ''
}

const getters = {
  notifications: state => state.notifications,
  loadMore: state => state.loadMore,
  isLoading: state => state.isLoading
}

const actions = {
  async readNotification({ commit, dispatch }, id) {
    commit('notificationsRequest')

    return await setIsRead(id)
      .then(() => {
        dispatch('getNotifications')
      })
      .catch(() => {
        commit('notificationsError')
      })
  },
  async getNotifications({ commit }) {
    commit('notificationsRequest')

    return await listNotifications()
      .then(resp => {
        commit('setNotifications', resp.data.results)
        commit('setLoadMore', resp.data.next)
      })
      .catch(() => {
        commit('notificationsError')
      })
  },
  getMoreNotifications({ commit }) {
    commit('notificationsRequest')
    listMoreNotifications(this.state.notifications.loadMore)
      .then(resp => {
        commit('setMoreNotifications', resp.data.results)
        commit('setLoadMore', resp.data.next)

        return resp
      })
      .catch(err => {
        commit('notificationsError')
        return err
      })
  }
}

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications
    state.isLoading = false
  },
  setMoreNotifications(state, notifications) {
    state.notifications = [...state.notifications, ...notifications]
    state.isLoading = false
  },
  setLoadMore(state, loadMore) {
    state.loadMore = loadMore
  },
  notificationsError() {
    state.isLoading = false
  },
  notificationsRequest(state) {
    state.isLoading = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
