const state = {
  notificationAlertIdCounter: 0,
  notificationAlerts: []
}

const getters = {
  notificationAlerts: state => state.notificationAlerts
}

const actions = {
  addNotificationAlert({ commit, state }, { type, text, timeout = 4000 }) {
    const notificationId = state.notificationAlertIdCounter
    commit('incrementNotificationAlertId')
    const timeoutHandler = setTimeout(() => {
      commit('removeNotificationAlert', notificationId)
    }, timeout)
    const notificationAlert = {
      type,
      text,
      timeoutHandler,
      id: notificationId
    }
    commit('addNotificationAlert', notificationAlert)
    return notificationAlert
  },
  removeNotificationAlert({ commit, state }, notificationId) {
    const notificationAlert = state.notificationAlerts.find(
      notificationAlert => notificationAlert.id === notificationId
    )
    clearTimeout(notificationAlert.timeoutHandler)
    commit('removeNotificationAlert', notificationId)
  }
}

const mutations = {
  incrementNotificationAlertId(state) {
    state.notificationAlertIdCounter++
  },
  addNotificationAlert(state, notificationAlert) {
    state.notificationAlerts = [...state.notificationAlerts, notificationAlert]
  },
  removeNotificationAlert(state, notificationId) {
    const index = state.notificationAlerts.findIndex(
      notificationAlert => notificationAlert.id === notificationId
    )
    state.notificationAlerts.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
