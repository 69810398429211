var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"text-center highlights"},[_c('v-container',{staticClass:"pa-14",attrs:{"grid-list-s":"","text-xs-center":""}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-sm-h4 font-weight-bold text-justify primary--text"},[_vm._v(" "+_vm._s(_vm.$t('wasiaAssetsIntro'))+" ")])])],1),_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"lg":"4"}},[_c('v-dialog',{attrs:{"max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10",attrs:{"hover":"","height":"100%"}},'v-card',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"custom-icon text-center ma-auto pa-10",attrs:{"x-large":"","color":"primary","title":"certificate icon"}},[_vm._v(" mdi-certificate-outline ")])],1),_c('h3',{staticClass:"secondary--text font-weight-black text-sm-h5"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSafety'))+" ")])])]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSafety'))+" ")]),_c('v-card-text',{staticClass:"subtitle-1 py-4"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSafetyText'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-dialog',{attrs:{"max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:" mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10",attrs:{"hover":"","height":"100%"}},'v-card',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"custom-icon text-center ma-auto pa-10",attrs:{"x-large":"","color":"primary","title":"certificate icon"}},[_vm._v(" mdi-account-multiple-outline ")])],1),_c('h3',{staticClass:"secondary--text font-weight-black text-sm-h5"},[_vm._v(" "+_vm._s(_vm.$t('wasiaConfidentiality'))+" "),_c('br'),_c('br')])])]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('wasiaConfidentiality'))+" ")]),_c('v-card-text',{staticClass:"subtitle-1 py-4"},[_vm._v(" "+_vm._s(_vm.$t('wasiaConfidentialityText'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-dialog',{attrs:{"max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto mb-5 mb-lg-0 mb-lg-3 py-16 px-10",attrs:{"hover":"","height":"100%"}},'v-card',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"custom-icon text-center ma-auto pa-10",attrs:{"x-large":"","color":"primary","title":"certificate icon"}},[_vm._v(" mdi-lock-outline ")])],1),_c('h3',{staticClass:"secondary--text font-weight-black text-sm-h5"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSecurity'))+" ")])])]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSecurity'))+" ")]),_c('v-card-text',{staticClass:"subtitle-1 py-4"},[_vm._v(" "+_vm._s(_vm.$t('wasiaSecurityText'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }