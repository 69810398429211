import _axios from '@/plugins/axios'

const END_POINT = '/api/accounts/wills/'

export const addWill = data =>
  _axios.post(END_POINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
export const getWill = () => _axios.get(`${END_POINT}me`)
