import _axios from '@/plugins/axios'

const END_POINT = '/api/consultations/'

export const listConsultations = () => _axios.get(`${END_POINT}threads/`)
export const listMoreConsultations = next => _axios.get(`${next}`)
export const create = consultation =>
  _axios.post(`${END_POINT}threads/`, consultation)
export const allSeen = id =>
  _axios.put(`${END_POINT}threads/${id}/`, { read: true })

export const reply = message =>
  _axios.post(`${END_POINT}messages/`, message, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
