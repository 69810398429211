import { checkoutSession } from '@/api/payments.api'

const state = {}

const getters = {}

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async getCheckoutSession({}, duration) {
    return await checkoutSession(duration)
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.log(err)
      })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
