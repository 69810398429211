'use strict'

import Vue from 'vue'
import axios from 'axios'

Vue.prototype.$http = axios

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'

const _axios = axios.create()

export default _axios
