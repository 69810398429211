<template>
  <div class="notifications-alerts-list">
    <v-slide-y-transition group>
      <v-alert
        v-for="notification in notificationAlerts"
        :key="notification.id"
        :type="notification.type"
        shaped
        dismissible
        @input="removeNotificationAlert(notification.id)"
      >
        {{ notification.text }}
      </v-alert>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: mapGetters(['notificationAlerts']),
  methods: mapActions(['removeNotificationAlert'])
}
</script>

<style>
.notifications-alerts-list {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  min-width: 320px;
  width: 33%;
}
</style>
