import { subscriptions, listMoreSubscriptions } from '@/api/subscriptions.api'

const state = {
  subscriptions: null,
  loadMoreSubscriptions: ''
}

const getters = {
  subscriptions: state => state.subscriptions,
  loadMoreSubscriptions: state => state.loadMoreSubscriptions
}

const actions = {
  async getSubscriptionHistory({ commit }) {
    return await subscriptions()
      .then(res => {
        commit('setSubscriptionHistory', res.data.results)
        commit('setSubscriptionLoadMore', res.data.next)
      })
      .catch(err => {
        console.log(err)
      })
  },
  async getMoreSubscription({ commit }, loadMoreSubscriptions) {
    await listMoreSubscriptions(loadMoreSubscriptions)
      .then(resp => {
        commit('setMoreSubscriptions', resp.data.results)
        commit('setSubscriptionLoadMore', resp.data.next)

        return resp
      })
      .catch(err => {
        commit('consultationsError')
        return err
      })
  }
}

const mutations = {
  setSubscriptionHistory(state, subscriptions) {
    state.subscriptions = subscriptions
  },
  setSubscriptionLoadMore(state, loadMoreSubscriptions) {
    state.loadMoreSubscriptions = loadMoreSubscriptions
  },
  setMoreSubscriptions(state, subscriptions) {
    state.subscriptions = [...state.subscriptions, ...subscriptions]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
