import _axios from '@/plugins/axios'

const END_POINT = '/api/accounts/users/'

export const getStatus = () => _axios.get(`${END_POINT}me/`)
export const register = data =>
  _axios.post(END_POINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

export const patchUser = data =>
  _axios.patch(`${END_POINT}me/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
export const deleteAccount = () => _axios.delete(`${END_POINT}me/`)
export const getUser = () => _axios.get(`${END_POINT}me/`)
