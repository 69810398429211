import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/consultations',
    name: 'MyConsultations',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true
    },
    component: () =>
      import(
        /* webpackChunkName: "consultations" */ '../views/MyConsultations.vue'
      )
  },
  {
    path: '/status',
    name: 'AccountStatus',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "status" */ '../views/AccountStatus.vue')
  },
  {
    path: '/reset',
    name: 'ForgotPassword',
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset/confirm',
    name: 'ForgotPasswordConfirm',
    component: () =>
      import(
        /* webpackChunkName: "reset" */ '../views/ForgotPasswordConfirm.vue'
      )
  },
  {
    path: '/legal-notice',
    name: 'LegalNotice',
    component: () =>
      import(/* webpackChunkName: "legal" */ '../views/LegalNotice.vue')
  },
  {
    path: '/who-we-are',
    name: 'WhoWeAre',
    component: () =>
      import(/* webpackChunkName: "whoWeAre" */ '../views/WhoWeAre.vue')
  },
  {
    path: '/wasia-help',
    name: 'WasiaHelp',
    component: () =>
      import(/* webpackChunkName: "wasiaHelp" */ '../views/WasiaHelp.vue')
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: () =>
      import(/* webpackChunkName: "terms" */ '../views/TermsOfService.vue')
  },
  {
    path: '/references',
    name: 'References',
    component: () =>
      import(/* webpackChunkName: "references" */ '../views/References.vue')
  },
  {
    path: '/#assets',
    name: 'Assets'
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../views/SubscriptionHistory.vue'
      )
  },
  {
    path: '/subscription/success',
    name: 'SubscriptionSuccess',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../views/SubscriptionSuccess.vue'
      )
  },
  {
    path: '/subscription/failure',
    name: 'SubscriptionFailure',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../views/SubscriptionFailure.vue'
      )
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/faqs',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/FAQ.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/Notifications.vue'
      )
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/my-will',
    name: 'MyWill',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "will" */ '../views/MyWill.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (to.matched.some(record => record.meta.requiresActiveAccount)) {
        if (store.getters.authStatus === store.getters.authStatusEnum.ACTIVE) {
          next()
        } else next('/status')
      } else next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
