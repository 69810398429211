import _axios from '@/plugins/axios'

const END_POINT = '/api/auth'

export const login = user => _axios.post(`${END_POINT}/login/`, user)
export const reset = email => _axios.post(`${END_POINT}/password/reset/`, email)
export const change = passwords =>
  _axios.post(`${END_POINT}/password/change/`, passwords)
export const confirmReset = passwordAndToken =>
  _axios.post(`${END_POINT}/password/reset/confirm`, passwordAndToken)
