import { wasiaHelp } from '@/api/wasiaHelp.api'

const state = {
  wasiaHelp: ''
}

const getters = {
  wasiaHelp: state => state.wasiaHelp
}

const actions = {
  async getWasiaHelp({ commit }) {
    await wasiaHelp()
      .then(resp => {
        commit('setWasiaHelp', resp.data.text)
      })
      .catch(err => console.log(err))
  }
}

const mutations = {
  setWasiaHelp(state, wasiaHelp) {
    state.wasiaHelp = wasiaHelp
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
