import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import notifications from './modules/notifications'
import countries from './modules/countries'
import consultations from './modules/consultations'
import profile from './modules/profile'
import subscriptions from './modules/subscriptions'
import faqs from './modules/faqs'
import legal from './modules/legal'
import will from './modules/will'
import payments from './modules/payments'
import notificationsAlerts from './modules/notificationsAlerts'
import otherDocuments from '@/store/modules/otherDocuments'
import wasiaHelp from '@/store/modules/wasiaHelp'
import whoWeAre from '@/store/modules/whoWeAre'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    countries,
    notifications,
    consultations,
    profile,
    subscriptions,
    faqs,
    legal,
    will,
    payments,
    notificationsAlerts,
    otherDocuments,
    wasiaHelp,
    whoWeAre
  },
  plugins: [createPersistedState()]
})
