<template>
  <v-footer padless color="primary">
    <v-container class="pa-2 ma-2">
      <v-row>
        <v-col col="6">
          <v-list-item-title class="white--text font-weight-bold pb-2 pl-4">{{
            $t('aboutUs')
          }}</v-list-item-title>
          <v-list dense color="primary">
            <v-list-item :to="{ name: 'WhoWeAre' }" link>
              <v-list-item-title
                class="white--text font-weight-bold text-body-2"
                >{{ $t('whoWeAre') }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item :to="{ name: 'FAQ' }" link>
              <v-list-item-title
                class="white--text font-weight-bold text-body-2"
                >{{ $t('faq') }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item :to="{ name: 'WasiaHelp' }" link>
              <v-list-item-title
                class="white--text font-weight-bold text-body-2"
                >{{ $t('wasiaHelp') }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-col>
        <v-col col="6">
          <v-list-item-title class="white--text font-weight-bold pl-4">{{
            $t('contact')
          }}</v-list-item-title>
          <v-list dense color="primary">
            <v-list-item class="col-10 col-sm-12 col-xs-12 col-md-12 pt-0">
              <v-list-item-content>
                <div class="white--text text-body-2 mb-4">
                  <span class="font-weight-bold">{{ $t('address') }}: </span>
                  <span class="fixed-font-size address-ltr"
                    >13 Rue de Chantepoulet - 1201 Genève
                  </span>
                </div>
                <v-list-item-subtitle class="white--text py-2">
                  <span class="font-weight-bold">{{ $t('mail') }}:</span>
                  <span class="fixed-font-size">
                    contact@wasiasig.com</span
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="px-4">
      <v-col class="white--text text-caption fixed-font-size" md="8" sm="12">
        Copyrights © {{ new Date().getFullYear() }} <br /><strong
          >The Wasia Swiss International Guardian Company
        </strong>
      </v-col>

      <v-col justify="end" class="white--text d-flex-inline">
        <router-link
          class="white--text font-weight-bold text-decoration-none"
          :to="{ name: 'TermsOfService' }"
          >{{ $t('termsOfService') }}</router-link
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'WasiaFooter'
})
</script>

<style scoped>
.address-ltr {
  display: inline-block;
  direction: ltr;
}
</style>
