import {
  listConsultations,
  listMoreConsultations,
  create,
  allSeen,
  reply
  //   uploadFile
} from '@/api/consultations.api'

const state = {
  consultations: [],
  consultationsIsLoading: false,
  consultationsLoadMore: '',
  currentConsultationThread: null,
  currentThreadMessages: null
}

const getters = {
  consultations: state => state.consultations,
  consultationsLoadMore: state => state.consultationsLoadMore,
  consultationsIsLoading: state => state.consultationsIsLoading,
  currentConsultationThread: state => state.currentConsultationThread,
  currentThreadMessages: state => state.currentThreadMessages
}

const actions = {
  setOpenConsultation({ commit, state, dispatch }, consultationId) {
    const consultation = state.consultations.find(
      item => item.id === consultationId
    )
    commit('setCurrentConsultationThread', consultation)
    dispatch('setAllSeen', consultation.id)
  },
  async getConsultations({ commit, state }) {
    commit('consultationsRequest')

    return await listConsultations()
      .then(resp => {
        commit('setConsultations', resp.data)
        commit('setConsultationsLoadMore', resp.data.next)

        if (
          state.consultations.find(
            item => item.id === state.currentConsultationThread.id
          )
        ) {
          commit(
            'setCurrentThreadMessages',
            resp.data.find(
              thread => thread.id === state.currentConsultationThread.id
            ).messages || []
          )
        } else {
          commit('setCurrentThreadMessages', [])
          commit('setCurrentConsultationThread', '')
        }
      })
      .catch(() => {
        commit('consultationsError')
      })
  },
  async creatConsultation({ commit, dispatch }, consultation) {
    commit('consultationsRequest')

    return await create(consultation)
      .then(async resp => {
        await dispatch('getConsultations')
        return resp
      })
      .catch(err => {
        commit('consultationsError')
        return err.response
      })
  },
  async sendReply({ commit, dispatch, state }, message) {
    commit('consultationsRequest')

    return await reply(message)
      .then(async resp => {
        await dispatch('getConsultations')
        dispatch('setOpenConsultation', state.currentConsultationThread.id)
        return resp
      })
      .catch(() => {
        commit('consultationsError')
      })
  },
  async setAllSeen({ commit, dispatch }, id) {
    commit('consultationsRequest')

    return await allSeen(id)
      .then(resp => {
        dispatch('getConsultations')
        return resp
      })
      .catch(() => {
        commit('consultationsError')
      })
  },
  async getMoreConsultations({ commit }, consultationsLoadMore) {
    commit('consultationsRequest')

    await listMoreConsultations(consultationsLoadMore)
      .then(resp => {
        commit('setMoreConsultations', resp.data)
        commit('setConsultationsLoadMore', resp.data.next)

        return resp
      })
      .catch(err => {
        commit('consultationsError')
        return err
      })
  }
}

const mutations = {
  setCurrentThreadMessages(state, messages) {
    state.currentThreadMessages = messages
  },
  setCurrentConsultationThread(state, consultation) {
    state.currentConsultationThread = consultation
  },
  setConsultations(state, consultations) {
    state.consultations = consultations
    state.consultationsIsLoading = false
  },
  setMoreConsultations(state, consultations) {
    state.consultations = [...state.consultations, ...consultations]
    state.consultationsIsLoading = false
  },
  setConsultationsLoadMore(state, consultationsLoadMore) {
    state.consultationsLoadMore = consultationsLoadMore
  },
  consultationsError() {
    state.consultationsIsLoading = false
  },
  consultationsRequest(state) {
    state.consultationsIsLoading = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
