<template>
  <div>
    <v-app-bar light class="pl-md-6  px-lg-8 mx-auto" height="111">
      <v-col
        class="d-flex text-center align-center  toolbar-container col-xl-10 pa-0 "
      >
        <div class="d-flex align-center">
          <router-link :to="{ name: 'Home' }">
            <v-img
              alt="Wasia Logo"
              class="shrink mr-2"
              contain
              :src="require('@/assets/logo.png')"
              transition="scale-transition"
              width="80"
              @click="selectedTab = null"
            />
          </router-link>

          <v-toolbar-title
            class="primary--text d-none d-sm-flex text-sm-h3 ms-4 text-capitalize font-weight-black navbar-title fixed-font-size"
            >Wasia</v-toolbar-title
          >
        </div>
        <div class="pb-2">
          <v-tabs
            v-model="selectedTab"
            align-with-title
            class="hidden-sm-and-down hidden-md-and-down"
            background-color="transparent"
            optional
          >
            <v-tab
              v-if="isLoggedIn"
              class="text-uppercase font-weight-bold  "
              :to="{ name: 'MyConsultations' }"
              :disabled="authStatus !== authStatusEnum.ACTIVE"
            >
              {{ $t('consultations') }}</v-tab
            >
            <v-tab
              v-if="!isLoggedIn"
              class="text-uppercase font-weight-bold  "
              to="/#assets"
              >{{ $t('ourAssets') }}</v-tab
            >
            <v-tab
              v-if="!isLoggedIn"
              class="text-uppercase font-weight-bold  "
              :to="{ name: 'References' }"
              >{{ $t('references') }}</v-tab
            >
            <v-tab
              v-if="isLoggedIn"
              class="text-uppercase font-weight-bold  "
              :to="{ name: 'Subscriptions' }"
              :disabled="authStatus !== authStatusEnum.ACTIVE"
            >
              {{ $t('subscriptions') }}</v-tab
            >
            <v-tab
              v-if="!isLoggedIn"
              class="text-uppercase font-weight-bold  "
              :to="{ name: 'Register' }"
              >{{ $t('register') }}</v-tab
            >
            <v-tab
              v-if="!isLoggedIn"
              class="text-uppercase font-weight-bold  "
              :to="{ name: 'Login' }"
            >
              {{ $t('login') }}</v-tab
            >
            <v-tab
              v-if="isLoggedIn"
              class="text-uppercase font-weight-bold  "
              @click="logoutUser"
              >{{ $t('logout') }}</v-tab
            >
          </v-tabs>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex flex-row">
          <div class="pt-2">
            <v-btn v-if="isLoggedIn" icon :to="{ name: 'Notifications' }">
              <v-badge
                dot
                :value="unreadNotifications.length > 0"
                color="error"
                overlap
              >
                <v-icon color="black" large>mdi-bell-outline</v-icon>
              </v-badge>
            </v-btn>
            <v-btn v-if="isLoggedIn" icon :to="{ name: 'MyWill' }">
              <v-icon color="black" large class="mb-2"
                >mdi-book-account-outline</v-icon
              >
            </v-btn>
            <v-btn v-if="isLoggedIn" icon :to="{ name: 'Profile' }">
              <v-icon color="black" large>mdi-account-outline</v-icon>
            </v-btn>

            <v-btn v-if="isLoggedIn" icon :to="{ name: 'Settings' }">
              <v-icon color="black" large>mdi-settings</v-icon>
            </v-btn>
            <v-app-bar-nav-icon
              class="hidden-lg-and-up"
              color="black"
              large
              @click.stop="drawer = !drawer"
            />
          </div>
          <v-select
            v-model="locale"
            :items="availableLocales"
            dense
            outlined
            width="1"
            class="pt-3 hidden-md-and-down locale-dropdown"
          />
        </div>
      </v-col>
    </v-app-bar>

    <v-navigation-drawer
      id="drawer"
      v-model="drawer"
      temporary
      fixed
      width="200"
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-if="isLoggedIn"
            :to="{ name: 'MyConsultations' }"
            :disabled="authStatus !== authStatusEnum.ACTIVE"
          >
            <v-list-item-title class="text-uppercase">{{
              $t('consultations')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isLoggedIn"
            :to="{ name: 'Subscriptions' }"
            :disabled="authStatus !== authStatusEnum.ACTIVE"
          >
            <v-list-item-title class="text-uppercase">{{
              $t('subscriptions')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn" to="/#assets">
            <v-list-item-title class="text-uppercase">{{
              $t('ourAssets')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn" :to="{ name: 'References' }">
            <v-list-item-title class="text-uppercase">{{
              $t('references')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isLoggedIn" :to="{ name: 'Register' }">
            <v-list-item-title class="text-uppercase">{{
              $t('register')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isLoggedIn" :to="{ name: 'Login' }">
            <v-list-item-title class="text-uppercase">{{
              $t('login')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click="logoutUser">
            <v-list-item-title class="text-uppercase">{{
              $t('logout')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="locale-dropdown">
            <v-select
              v-model="locale"
              :items="availableLocales"
              dense
              outlined
              width="2"
            />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'NavBar',
  data: () => ({
    drawer: null,
    selectedTab: null
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      authStatus: 'authStatus',
      notifications: 'notifications',
      authStatusEnum: 'authStatusEnum'
    }),
    availableLocales() {
      return [
        {
          text: this.$t('English'),
          value: 'en'
        },
        {
          text: this.$t('Arabic'),
          value: 'ar'
        },
        {
          text: this.$t('French'),
          value: 'fr'
        }
      ]
    },
    locale: {
      get() {
        return this.$i18n.locale
      },
      set(newLocale) {
        this.$cookies.set('django_language', newLocale)
        window.location.reload()
      }
    },
    unreadNotifications() {
      return this.notifications?.filter(alert => !alert.is_read) || []
    }
  },
  mounted() {
    if (this.isLoggedIn)
      window.setInterval(async () => {
        await this.getNotifications()
      }, 10000)
  },
  methods: {
    ...mapActions(['logout', 'getNotifications']),
    logoutUser: function() {
      this.logout().then(() => {
        this.$router.push('/')
      })
    }
  }
})
</script>
<style lang="scss">
.locale-dropdown {
  max-width: 140px !important;
  fieldset {
    border-color: #00665b !important;
  }
}
.toolbar-container {
  max-width: 1500px;
  margin: auto;
}
</style>
