import { login, reset, change, confirmReset } from '@/api/auth.api'
import { getStatus, deleteAccount, register } from '@/api/accounts.api'

const state = {
  user: null,
  isLoggedIn: localStorage.getItem('isLoggedIn') || false,
  userStatus: null,
  registrationStatus: null,
  authStatusEnum: {
    AWAITING_CONFIRMATION: 1,
    AWAITING_PAYMENT: 2,
    ACTIVE: 3,
    SUSPENDED: 4
  }
}

const getters = {
  user: state => state.user,
  isLoggedIn: state => state.isLoggedIn,
  authStatus: state => state.userStatus,
  registrationStatus: state => state.registrationStatus,
  authStatusEnum: state => state.authStatusEnum
}

const actions = {
  async deleteUserAccount({ commit }) {
    commit('authRequest')

    await deleteAccount()
      .then(resp => {
        return resp
      })
      .catch(err => {
        commit('authError')
        return err
      })
  },
  async login({ commit }, user) {
    commit('authRequest')

    return await login(user)
      .then(resp => {
        const userEmail = resp.data.email
        commit('authSuccess', userEmail)
        return resp
      })
      .catch(err => {
        commit('authError')
        return err.response
      })
  },
  async getUserStatus({ commit }) {
    await getStatus()
      .then(resp => {
        const userStatus = resp.data.user.status
        commit('setUserStatus', userStatus)
        return resp
      })
      .catch(err => {
        return err
      })
  },
  async logout({ commit }) {
    commit('logout')
  },
  async resetPassword({ commit }, email) {
    commit('authRequest')

    return await reset({ email })
      .then(res => {
        return res
      })
      .catch(err => {
        return err.response
      })
  },
  async confirmResetPassword({ commit }, data) {
    commit('authRequest')
    return await confirmReset(data)
      .then(res => {
        return res
      })
      .catch(err => {
        return err.response
      })
  },
  async register({ commit }, data) {
    commit('registrationRequest')
    return await register(data)
      .then(resp => {
        commit('registrationSuccess')
        return resp
      })
      .catch(err => {
        commit('registrationError')
        return err.response
      })
  },
  async changePassword({ commit }, passwords) {
    commit('authRequest')

    return await change(passwords)
      .then(res => {
        return res
      })
      .catch(err => {
        return err.response
      })
  }
}

const mutations = {
  setUserStatus(state, userStatus) {
    state.userStatus = userStatus
  },
  authRequest(state) {
    state.userStatus = 'loading'
  },
  authSuccess(state, userEmail) {
    // state.userStatus = 'success'
    state.isLoggedIn = true
    localStorage.setItem('isLoggedIn', true)
    state.user = { email: userEmail }
  },
  authError(state) {
    state.userStatus = 'error'
    localStorage.removeItem('isLoggedIn')
  },
  logout(state) {
    state.userStatus = null
    localStorage.removeItem('isLoggedIn')
    state.isLoggedIn = false
  },
  registrationRequest(state) {
    state.registrationStatus = 'loading'
  },
  registrationSuccess(state) {
    state.registrationStatus = 'success'
  },
  registrationError(state) {
    state.registrationStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
