import { listCountries } from '@/api/countries.api'

const state = {
  countries: []
}

const getters = {
  countries: state => state.countries
}

const actions = {
  async getCountries({ commit }) {
    return await listCountries()
      .then(resp => {
        const countries = resp.data
        commit('setCountries', countries)
      })
      .catch(() => {
        commit('countriesError')
      })
  }
}

const mutations = {
  setCountries(state, countries) {
    state.countries = countries
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
