<template>
  <section class="text-center">
    <v-container grid-list-s text-xs-center class="px-12">
      <v-layout row justify-center>
        <v-col md="6" sm="12" class="py-16 py-md-2">
          <figure>
            <img
              alt="Wasia Illustration"
              :src="require('@/assets/wasia_illustration.jpeg')"
              title="Wasia Illustration"
              width="100%"
            />
          </figure>
        </v-col>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroSection'
})
</script>
<style lang="scss">
.custom-input {
  input::placeholder {
    opacity: 1;
    font-weight: 600;
    font-size: 1.3rem;
  }
}
</style>
