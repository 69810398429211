<template>
  <main>
    <IntroSection />
    <QuotesSection />
    <AssetsSection id="assets" />
  </main>
</template>

<script>
import Vue from 'vue'

import IntroSection from '@/components/IntroSection.vue'
import QuotesSection from '@/components/QuotesSection.vue'
import AssetsSection from '@/components/AssetsSection.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    AssetsSection,
    IntroSection,
    QuotesSection
  }
})
</script>
