<template>
  <v-app>
    <NavBar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <NotificationAlerts />
    <WasiaFooter />
  </v-app>
</template>

<script>
import Vue from 'vue'
import NavBar from '@/components/NavBar.vue'
import WasiaFooter from '@/components/WasiaFooter.vue'
import NotificationAlerts from '@/components/NotificationAlerts'

export default Vue.extend({
  name: 'App',
  components: {
    NotificationAlerts,
    NavBar,
    WasiaFooter
  }
})
</script>
