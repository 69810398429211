import Vue from 'vue'
import './plugins/axios'
import './filters/to-local-tz'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueGtm from '@gtm-support/vue2-gtm'
import i18n, { selectedLocale } from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import vueCookies from 'vue-cookies'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import moment from 'moment'
import vueMoment from 'vue-moment'
import 'moment/locale/ar'
import 'moment/locale/fr'

Vue.config.productionTip = false

moment.locale(selectedLocale)

Vue.use(vueCookies)
Vue.use(vueMoment, {
  moment
})

Vue.use(VueGtm, {
  id: 'GTM-K7WWFS8',
  defer: true,
  compatibility: true,
  vueRouter: router
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  created() {
    const getHTMLTag = document.documentElement
    getHTMLTag.setAttribute('lang', i18n.locale)
  },
  render: h => h(App)
}).$mount('#app')
