import { addWill, getWill } from '@/api/will.api'

const state = {
  will: '',
  willError: ''
}

const getters = {
  will: state => state.will,
  willError: state => state.willError
}

const actions = {
  async retrieveWill({ commit }) {
    await getWill()
      .then(resp => {
        commit('setWill', resp.data)
        commit('setWillError', '')
      })
      .catch(err => {
        if (!err.response) commit('setWillError', 'Network Error')
        else commit('setWillError', '')
        commit('setWill', '')
        console.log(err)
      })
  },
  async postWill({ commit }, formData) {
    await addWill(formData)
      .then(resp => {
        commit('setWill', resp.data)
        commit('setWillError', '')
      })
      .catch(err => {
        if (err.response) commit('setWillError', err.response.data.detail)
        else commit('setWillError', 'Network Error')

        console.log(err)
      })
  }
}

const mutations = {
  setWill(state, will) {
    state.will = will
  },
  setWillError(state, willError) {
    state.willError = willError
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
