import _axios from '@/plugins/axios'

const END_POINT = '/api/accounts/other-documents/'

export const addOtherDocument = data =>
  _axios.post(END_POINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
export const getOtherDocuments = () => _axios.get(END_POINT)

export const deleteOtherDocument = documentId =>
  _axios.delete(`${END_POINT}${documentId}`)
