import { whoWeAre } from '@/api/whoWeAre.api'

const state = {
  whoWeAre: ''
}

const getters = {
  whoWeAre: state => state.whoWeAre
}

const actions = {
  async getWhoWeAre({ commit }) {
    await whoWeAre()
      .then(resp => {
        commit('setWhoWeAre', resp.data.text)
      })
      .catch(err => console.log(err))
  }
}

const mutations = {
  setWhoWeAre(state, whoWeAre) {
    state.whoWeAre = whoWeAre
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
