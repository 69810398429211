import {
  addOtherDocument,
  getOtherDocuments,
  deleteOtherDocument
} from '@/api/otherDocuments.api'

const state = {
  otherDocuments: [],
  otherDocumentsError: ''
}

const getters = {
  otherDocuments: state => state.otherDocuments,
  otherDocumentsError: state => state.otherDocumentsError
}

const actions = {
  async getOtherDocuments({ commit }) {
    await getOtherDocuments()
      .then(resp => {
        commit('setOtherDocuments', resp.data)
        commit('setOtherDocumentsError', '')
      })
      .catch(() => {
        commit('setOtherDocumentsError', 'Network Error')
      })
  },
  async postOtherDocument({ commit }, formData) {
    await addOtherDocument(formData)
      .then(resp => {
        commit('addOtherDocument', resp.data)
        commit('setOtherDocumentsError', '')
      })
      .catch(err => {
        if (err.response)
          commit('setOtherDocumentsError', err.response.data.detail)
        else commit('setOtherDocumentsError', 'Network Error')
      })
  },
  async deleteOtherDocument({ commit }, documentId) {
    await deleteOtherDocument(documentId)
      .then(() => {
        commit('removeOtherDocument', documentId)
        commit('setOtherDocumentsError', '')
      })
      .catch(err => {
        if (err.response)
          commit('setOtherDocumentsError', err.response.data.detail)
        else commit('setOtherDocumentsError', 'Network Error')
      })
  }
}

const mutations = {
  setOtherDocuments(state, otherDocuments) {
    state.otherDocuments = otherDocuments
  },
  addOtherDocument(state, otherDocument) {
    state.otherDocuments = [otherDocument, ...state.otherDocuments]
  },
  removeOtherDocument(state, documentId) {
    const index = state.otherDocuments.findIndex(
      document => document.pk === documentId
    )
    state.otherDocuments.splice(index, 1)
  },
  setOtherDocumentsError(state, otherDocumentsError) {
    state.otherDocumentsError = otherDocumentsError
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
